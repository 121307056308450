import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Stack, Typography } from '@mui/material';
import { Button, Dialog, Input } from '@nordictrustee/nt-ui-library';
import moment from 'moment';
import { useGlobal, useToast } from 'modules/Global';
import { confirmClose } from 'utils/hooks/confirmClose';
import { submitUpdatedReporting, sumbitNewReporting } from '../../ReportingApi';
import { useReporting } from '../../ReportingContext';
import {
  InformationCovenantDTO,
  ReportingDetailsDTO,
  ReportingSubmitNewDTO,
  ReportingSubmitUpdateDTO,
} from '../../types';
import { FileInfo } from './FileUploads/types';
import { Deliveries } from './Deliveries';
import { FileUploads } from './FileUploads';
import { uploadReportingDocuments } from './uploadReportingDocuments';

interface Props {
  handleClose: (updatedReportID?: number) => void;
  covenantScheduleID: number;
  reportingName: string;
  reportingDate: string;
  referenceDate: string;
  reportingDetails: ReportingDetailsDTO;
  isEditMode: boolean;
}

interface Form {
  files: FileInfo[];
  comment: string;
  hasConfirmedNoEventOfDefault?: boolean;
  informationCovenants: InformationCovenantDTO[];
}

export const ReportingSubmitForm: FC<Props> = ({
  handleClose,
  covenantScheduleID,
  reportingName,
  reportingDate,
  referenceDate,
  reportingDetails,
  isEditMode,
}: Props) => {
  const { loanID, isSwedishTrustee } = useReporting();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showError, showSuccess } = useToast();
  const {
    control,
		formState: { isDirty },
    watch,
    getValues,
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      informationCovenants: reportingDetails.informationCovenants || [],
      comment: reportingDetails.comment || '',
      hasConfirmedNoEventOfDefault: isEditMode
        ? reportingDetails.hasConfirmedNoEventOfDefault
        : undefined,
      files:
        reportingDetails.complianceDocuments.map((complianceDocument) => ({
          publish: complianceDocument.isPublishRequested,
          ...complianceDocument,
        })) || [],
    },
    mode: 'onSubmit',
  });

  const files = watch('files') || [];
  const { activeCompany } = useGlobal();
  if (!activeCompany) return null;
  const { companyID } = activeCompany;
  const title = `Reporting - ${reportingName} - ${moment(reportingDate).format('L')} (${moment(referenceDate).format('L')})`;

  const handleConfirmClose = () => {
    confirmClose(isDirty, handleClose);
  };

  const submitUpdatedReportingWithFiles = async (formData: Form) => {
    if (isEditMode) {
      const dataDTO: ReportingSubmitUpdateDTO = {
        complianceReport: {
          id: reportingDetails.id,
          informationCovenantStates: formData.informationCovenants.map(
            (covenant: any) => {
              return {
                id: covenant.id,
                isAvailableFromPublicSources:
                  covenant.isAvailableFromPublicSources,
              };
            },
          ),
          covenantScheduleID: covenantScheduleID,
          comment: formData.comment,
          hasConfirmedNoEventOfDefault: formData.hasConfirmedNoEventOfDefault!,
        },
        complianceDocuments: formData.files
          .filter((file) => {
            return file.id != null;
          })
          .map((file) => {
            return { id: file.id!, isPublishRequested: file.publish };
          }),
      };

      await submitUpdatedReporting(+loanID, reportingDetails.id, dataDTO);
      await uploadNewFiles(formData.files, reportingDetails.id);

      return reportingDetails.id;
    }
  };

  const submitNewReportingWithFiles = async (formData: Form) => {
    const dataDTO: ReportingSubmitNewDTO = {
      informationCovenantStates: formData.informationCovenants.map(
        (covenant) => {
          return {
            id: covenant.id,
            isAvailableFromPublicSources: covenant.isAvailableFromPublicSources,
          };
        },
      ),
      covenantScheduleID: covenantScheduleID,
      comment: formData.comment,
      hasConfirmedNoEventOfDefault: formData.hasConfirmedNoEventOfDefault!,
    };

    const response = await sumbitNewReporting(
      +loanID,
      covenantScheduleID,
      dataDTO,
    );

    await uploadNewFiles(formData.files, response);
    return response;
  };

  const uploadNewFiles = async (
    files: FileInfo[],
    reportingDetailID: number,
  ) => {
    const newFiles = files.filter((file) => {
      return file.id == null;
    });
    if (newFiles.length > 0) {
      await uploadReportingDocuments(
        +loanID,
        reportingDetailID,
        covenantScheduleID,
        companyID,
        newFiles.map((f) => f.file),
        newFiles.map((f) => f.publish),
      );
    }
  };

  const onValidSubmit = async () => {
    setIsSubmitting(true);
    const currentFormValues = getValues();
    try {
      const updateReportID = isEditMode
        ? await submitUpdatedReportingWithFiles(currentFormValues)
        : await submitNewReportingWithFiles(currentFormValues);
      showSuccess('Report has been successfully submitted');
      handleClose(updateReportID);
    } catch (error) {
      console.error(error);
      showError('An error occurred while submitting the report');
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog
      onClose={handleConfirmClose}
      open={true}
      title={title}
      maxWidth="md"
      fullWidth
      dialogActions={
        <>
          <Button form="report-form" type="submit" isLoading={isSubmitting}>
            Submit
          </Button>
          <Button variant="outlined" onClick={() => handleClose()}>
            Cancel
          </Button>
        </>
      }
    >
      <Grid
        component="form"
        container
        display="flex"
        onSubmit={handleSubmit(onValidSubmit)}
        noValidate
        id="report-form"
      >
        <Grid item xs={12}>
          <Controller
            control={control}
            name="informationCovenants"
            defaultValue={[]}
            render={({ field: { value, onChange } }) => {
              return (
                <Deliveries
                  informationCovenants={value}
                  onChange={(
                    informationCovenants: InformationCovenantDTO[],
                  ) => {
                    onChange(informationCovenants);
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <Typography variant="h3" mb={1}>
            Reporting
          </Typography>
          <Stack spacing={3}>
            <Controller
              control={control}
              name="files"
              defaultValue={[]}
              render={({ field: { onChange } }) => {
                return (
                  <FileUploads
                    files={files}
                    onChange={onChange}
                    isEditMode={isEditMode}
                    isSwedishTrustee={isSwedishTrustee}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="comment"
              render={({
                field: { value, onChange, ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <Input.Text
                  label="Comment to Nordic Trustee"
                  onChange={onChange}
                  value={value}
                  multiline
                  rows={5}
                  error={!!error?.message}
                  helperText={error?.message}
                  {...rest}
                />
              )}
            />
            <Controller

              render={({
                         field: { value, onChange },
                         fieldState: { error },
                       }) => (
                <Input.Radio
                  title="We confirm that, to the best of our knowledge, no event of default has occurred or is likely to occur."
                  name="hasConfirmedNoEventOfDefault"
                  value={value}
                  onChange={onChange}
                  data={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  required
                  errorMessage={error?.message}
                />
              )}
              name="hasConfirmedNoEventOfDefault"
              control={control}
              rules={{
                validate: (value) => {
                  if (value === undefined) {
                    return 'This field is required';
                  }
                  return true;
                },
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Dialog>
  );
};
