import { FC } from 'react';
import { Typography } from '@mui/material';
import { LoadingContainer } from 'components/Loaders';
import classes from './EventOfDefault.module.css';

export const EventOfDefault: FC<{ hasConfirmedNoEventOfDefault: boolean, isLoading: boolean }> = ({ hasConfirmedNoEventOfDefault, isLoading }) => {
  return (
    <div className={classes.container}>
      <LoadingContainer loading={isLoading} type="singleList" rows={1} cols={2}>
        <div className={classes.row}>
          <Typography variant="body1" color="textSecondary" pb={1}>Event of Default</Typography>
          <Typography>{hasConfirmedNoEventOfDefault ? 'yes' : 'no'}</Typography>
        </div>
      </LoadingContainer>
    </div>
  );
};