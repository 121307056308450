import { useState } from 'react';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker as MDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import moment from 'moment';
import { EventDateFilterOption } from './dateFilterOptions';
import { setDatesWhenValid } from './setDatesWhenValid';
import classes from './DateFilterList.module.css';

interface ComponentProps {
  dateFilters: EventDateFilterOption[];
  label?: string;
  setDates: (
    id: string,
    fromDate?: moment.Moment,
    toDate?: moment.Moment,
  ) => void;
}

export const DateFilterList = ({
  dateFilters,
  setDates,
  label,
}: ComponentProps) => {
  const customDateFilter = dateFilters.find((df) => df.id === 'custom');
  const [pickerFromDate, setPickerFromDate] = useState(
    customDateFilter ? customDateFilter.fromDate : undefined,
  );
  const [pickerToDate, setPickerToDate] = useState(
    customDateFilter ? customDateFilter.toDate : undefined,
  );

  const datePickerSlotProps: DatePickerSlotsComponentsProps<moment.Moment> = {
    textField: {
      variant: 'standard',
    },
  };
  return (
    <div>
      {label && <FormLabel>{label}</FormLabel>}

      <div className={classes.filterButtons}>
        {dateFilters.map(({ id, selected, fromDate, toDate, text }) => (
          <Button
            data-id={`dateFilter-${id}`}
            data-selected={selected}
            key={id}
            onClick={() => {
              setDatesWhenValid(
                setDates,
                id,
                id === 'custom' ? moment() : fromDate,
                id === 'custom' ? undefined : toDate,
              );
            }}
            className={classes.filterSelector}
            variant={selected ? 'contained' : 'outlined'}
            size="small"
            color="primary"
          >
            {text}
          </Button>
        ))}
      </div>

      {customDateFilter && customDateFilter.selected && (
        <>
          <MDatePicker
            slotProps={datePickerSlotProps}
            className={classes.dateField}
            label="From Date"
            value={pickerFromDate || null}
            format={moment.localeData().longDateFormat('L')}
            onChange={(date: any) => {
              const newDate = date || undefined;
              setPickerFromDate(newDate);
              setDatesWhenValid(setDates, 'custom', newDate, pickerToDate);
            }}
          />
          <MDatePicker
            slotProps={datePickerSlotProps}
            className={classes.dateField}
            label="To Date"
            value={pickerToDate || null}
            format={moment.localeData().longDateFormat('L')}
            onChange={(date: any) => {
              const newDate = date || undefined;
              setPickerToDate(newDate);
              setDatesWhenValid(setDates, 'custom', pickerFromDate, newDate);
            }}
          />
        </>
      )}
    </div>
  );
};
