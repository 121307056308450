import React from 'react';
import { DatePicker as MDatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import {DatePickerSlotsComponentsProps} from "@mui/x-date-pickers/DatePicker/DatePicker.types";

interface ComponentProps {
  value: moment.Moment;
  changeHandler: (date: moment.Moment) => void;
  label?: string;
  minDate?: Date;
}

const datePickerSlotProps: DatePickerSlotsComponentsProps<moment.Moment> = {
  textField: {
    variant: 'standard'
  }
};
export const DatePicker = ({
  value, changeHandler, label, minDate,
}: ComponentProps) => (
  <MDatePicker
    slotProps={datePickerSlotProps}
    label={label || 'Date'}
    value={value}
    minDate={minDate ? moment(minDate) : moment()}
    onChange={(v:any) => changeHandler(v as moment.Moment)}
    format={moment.localeData().longDateFormat('L')}
  />
);

