import {
  BasicLoanDTO, LoanDTO
} from 'api/api.types';

export function sortLoansByDisbursement(loans: BasicLoanDTO[]) {
  return loans.sort((a, b) => {
    if (!a || !b || !a.issueDate || !b.issueDate) { return -1; }

    if (a.issueDate < b.issueDate) { return 1; }
    if (a.issueDate
      > b.issueDate) { return -1; }
    return 0;
  });
}

interface IDict {
  [id: string]: number;
}

export function calcTotalOutstandingByCurrency(loans: BasicLoanDTO[]): IDict {
  const loansByCurrency: IDict = {};
  loans.forEach((loan) => {
    loansByCurrency[loan.currency || ''] = loansByCurrency[loan.currency || ''] || 0;
    loansByCurrency[loan.currency || ''] += loan.outstandingAmount || 0;
  });
  return loansByCurrency;
}

export function calcAmountLeft(loan: BasicLoanDTO): number {
  const maximumAmount = loan.maximumAmount || 0;
  const outstandingAmount = loan.outstandingAmount || 0;
  return maximumAmount > 0 ? maximumAmount - outstandingAmount : 0;
}


export function hasLoanInterestPaymentDetails(loan: LoanDTO): boolean {
  // ATM we only support detailed payment for Swedish loans
  return (loan.isin || '').slice(0, 2) === 'SE';
}

export function isLoanActive(loan: BasicLoanDTO) {
  return loan.lastPossibleMaturityDate && new Date(loan.lastPossibleMaturityDate) > new Date();
}
