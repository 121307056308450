import React from 'react';
import Typography from '@mui/material/Typography';
import classes from './Label.module.css';

interface ComponentProps {
  label: string;
  value: string | number;
  color: string;
}
interface ExtendedCssProps extends React.CSSProperties {
  '--info-chart-label-color'?: string;
}
export const Label = ({ label, value, color }: ComponentProps) => {
  const rootStyle: ExtendedCssProps = {
    '--info-chart-label-color': color,
  };
  return (<div
    className={classes.root}
    style={rootStyle}
  >
    <div className={classes.inner} />
    <div>
      <Typography>{label}</Typography>
      <Typography className={classes.value}>
        {value}
      </Typography>
    </div>
  </div>);
};