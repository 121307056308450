import React from 'react';
import { InfoDoughnut } from 'modules/Loans';

interface ComponentProps {
  outstandingAmount?: number | null;
  maximumAmount?: number | null;
  currency?: string;
}

const LoanAmountChart = ({
  outstandingAmount, maximumAmount, currency,
}: ComponentProps) => (
  <InfoDoughnut
    currentValue={outstandingAmount || 0}
    maximumValue={maximumAmount || 0}
    currentValueLabel="Outstanding"
    completeText="Fully Drawn"
    inProgressText="Undrawn Amount"
    secondaryLabel="Undrawn Amount"
    currency={currency || 'SEK'}
  />
);

export default LoanAmountChart;
