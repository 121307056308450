import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';
import {
  ManagerDistributionDTO,
  ManagerDistributionRowDTO,
} from 'api/api.types';
import {
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
  TooltipItem,
} from 'chart.js';
import { getChartColor } from 'modules/Global/theme';
import { formatMoney } from 'utils/money';

interface ManagerDistributionProps{
  managerDistribution: ManagerDistributionDTO | undefined;
  currency: string | undefined;
}

const getChartData = (managerDistribution: ManagerDistributionDTO | undefined): ChartData<'doughnut'> => {
  if (!managerDistribution?.managerDistribution) {
    return {datasets: []};
  }
  const ms: ManagerDistributionRowDTO[] = managerDistribution.managerDistribution;
  return ({
    datasets: [{
      data: ms.map((m) => m.amount || 0),
      backgroundColor: ms.map((m, i) => getChartColor(i)),
    }],
    labels: ms.map((m) => `${m.manager} (${m.percentage}%)` || ''),
  });
};

const getOptions = (currency: string | undefined): ChartOptions<'doughnut'> => ({
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItem: TooltipItem<keyof ChartTypeRegistry>) {
          const label: string = tooltipItem.label || 'N/A';
          const val: number = tooltipItem.raw as number;
          return `${label}: ${formatMoney(val, currency)}`;
        },
      },
    },
    legend: {
      position: 'top'
    }
  },
  responsive: true,
  maintainAspectRatio: false
});

export const ManagerDistributionChart = ({ managerDistribution, currency }: ManagerDistributionProps) => {
  const data = getChartData(managerDistribution);
  return (
    <Box style={{height: '237px'}} mt={1}>
      <Doughnut data={data} options={getOptions(currency)} />
    </Box>
  );
};