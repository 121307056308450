import { FC, memo } from 'react';
import { Container } from '@nordictrustee/nt-ui-library';
import { LoadingContainer } from 'components/Loaders';
import { useReporting } from '../ReportingContext';
import { ReportingEventDTO } from '../types';
import { ReportingList } from './ReportingList';
import classes from './ReportingListContainer.module.css';

interface ReportingListProps {
  loading: boolean;
  reportingEvents?: ReportingEventDTO[];
}

export const ReportingListContainer: FC<ReportingListProps> = memo(
  ({ loading, reportingEvents }) => {
    const {selectEvent} = useReporting();
    return (
      <Container
        isHeader={false}
        data-cy="reporting-list-container"
        className={classes.container}
        overflow="auto"
      >
        <LoadingContainer
          loading={loading}
          type="listWithIcon"
          rows={15}
          cols={4}
        />
        <ReportingList
          loading={loading}
          reportingEvents={reportingEvents}
          selectEvent={selectEvent}
        />
      </Container>
    );
  },
);
