import React, { useState, useEffect, useMemo } from 'react';
import { ValueLabelOption } from 'legacy/types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';
import { SubmitButton } from 'components/form';
import Box from '@mui/material/Box';
import { DatePicker } from './DatePicker';
import { Typography } from '@mui/material';
import { BalanceSheetRequestDTO } from 'api/api.types';
import {
  EmailTextField,
  NameTextField,
  OptionalTextField,
  PhoneNumberTextField,
  ReadOnlyStringTextField,
} from 'components/form/text_fields/TextFields';
import { CreateMaterialUiChangeHandler } from 'legacy/inputHandlers';
import SimpleSelect from 'components/form/selects/simple_select/SimpleSelect';
import { HelperText } from 'components/form';
import { CompanyDTO } from 'modules/Global/types';
import { sendBalanceSheetRequest } from './sendBalanceSheetRequest';
import { useToast } from '../../../Providers';

interface OwnProps {
  company?: CompanyDTO;
  companies: CompanyDTO[];
  onSubmitted: () => void;
}

type ComponentProps = OwnProps;

export const BalanceSheetRequestForm: React.FC<ComponentProps> = ({
  company,
  companies,
  onSubmitted,
}) => {
  const initialDate = moment()
    .add(-1, 'year')
    .month(11)
    .endOf('month')
    .toISOString();

  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState(company);

  const [request, setRequest] = useState<BalanceSheetRequestDTO>({
    companyFunctionID: company != null ? company.companyID : 0,
    reportingDate: initialDate,
    auditorCompanyName: '',
    auditorName: '',
    auditorEmail: '',
    auditorPhone: '',
    auditorPostalAddress: '',
  });

  useEffect(() => {
    if (selectedCompany) {
      setRequest((prevState) => ({
        ...prevState,
        companyFunctionID: selectedCompany.companyID,
      }));
    }
  }, [selectedCompany]);

  const dateChangeHandler = (date: moment.Moment) => {
    setRequest((prevState) => ({
      ...prevState,
      reportingDate: date.toISOString(),
    }));
  };
  const { showWarning, showSuccess } = useToast();
  const sendRequest = async (request: BalanceSheetRequestDTO) => {
    try {
      await sendBalanceSheetRequest(request);
      showSuccess(
        'Balance sheet request has been successfully sent');
      return true;
    } catch (e) {
      let msg = '';
      if ((e as any).response?.data) {
        msg = (e as any).response.data;
      } else if ((e as any).message) {
        msg = (e as any).message;
      }
      showWarning('Failed to send balance sheet request, please try again later');
      console.error(msg);
    }
    return false;
  };

  const handleSubmit = async () => {
    if (sendingRequest) {
      return; // could potentially be replaced with canceling current request and sending a new one.
    }
    setSendingRequest(true);
    if (await sendRequest(request)) {
      onSubmitted();
    }
    setSendingRequest(false);
  };

  const companyChangeHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > = (e) => {
    const company = companies.find(
      (a: CompanyDTO) => a.companyID === +e.target.value,
    );
    setSelectedCompany(company);
  };

  const addressChangeHandler = CreateMaterialUiChangeHandler<string>(
    (_key, value) => {
      setRequest((prevState) => ({
        ...prevState,
        auditorPostalAddress: value.toString(),
      }));
    },
  );

  const phoneChangeHandler = CreateMaterialUiChangeHandler<string>(
    (_key, value) => {
      setRequest((prevState) => ({
        ...prevState,
        auditorPhone: value.toString(),
      }));
    },
  );

  const emailChangeHandler = CreateMaterialUiChangeHandler<string>(
    (_key, value) => {
      setRequest((prevState) => ({
        ...prevState,
        auditorEmail: value.toString(),
      }));
    },
  );

  const companyNameChangeHandler = CreateMaterialUiChangeHandler<string>(
    (_key, value) => {
      setRequest((prevState) => ({
        ...prevState,
        auditorCompanyName: value.toString(),
      }));
    },
  );

  const nameChangeHandler = CreateMaterialUiChangeHandler<string>(
    (_key, value) => {
      setRequest((prevState) => ({
        ...prevState,
        auditorName: value.toString(),
      }));
    },
  );

  const getSelectableCompanies = useMemo(() => {
    const selectableCompanies: ValueLabelOption[] = [];

    if (companies != null) {
      companies.forEach((company: CompanyDTO) => {
        selectableCompanies.push({
          value: String(company.companyID),
          label: company.name,
        });
      });
    }
    return selectableCompanies;
  }, [companies]);
  return (
    <ValidatorForm
      onSubmit={handleSubmit}
      onError={(errors: any) => console.log(errors)}
      instantValidate
    >
      <Box mt={2}>
        <SimpleSelect
          label="Company Name"
          value={selectedCompany ? String(request.companyFunctionID) : ''}
          items={getSelectableCompanies}
          changeHandler={companyChangeHandler}
        />
        <ReadOnlyStringTextField
          label="Organization No"
          value={selectedCompany != null ? selectedCompany.organizationNo : ''}
          field="organizationNo"
        />
        <Box mt={4}>
          <Typography>
            Please specify contact details for your auditor below:
          </Typography>
          <NameTextField
            label="Company Name"
            value={request.auditorCompanyName}
            changeHandler={companyNameChangeHandler}
            required
          />
          <NameTextField
            label="Attention/Name"
            value={request.auditorName}
            changeHandler={nameChangeHandler}
            required
          />
          <EmailTextField
            value={request.auditorEmail}
            changeHandler={emailChangeHandler}
            required
          />
          <PhoneNumberTextField
            value={request.auditorPhone}
            changeHandler={phoneChangeHandler}
            required={false}
          />
          <OptionalTextField
            label="Postal Address"
            field="auditorPostalAddress"
            value={request.auditorPostalAddress}
            changeHandler={addressChangeHandler}
            required={false}
          />
          <DatePicker
            value={moment(request.reportingDate)}
            changeHandler={dateChangeHandler}
            label="Reporting Date"
            minDate={new Date('1900-01-01')}
          />
        </Box>
        <Box mt={2} fontWeight="fontWeightBold">
          <HelperText>
            The information provided in the auditor confirmation is
            automatically generated based on the provisions set out in the
            relevant loan documentation, and Nordic Trustee does not
            automatically receive information of any payment delays or incorrect
            payments made through any CSD. Accordingly, the accuracy of
            information cannot be guaranteed. The auditor confirmation will
            cover all bonds issued by the company under which the relevant
            Nordic Trustee company has been appointed as trustee, and typically
            includes the following information:
          </HelperText>
          <HelperText>- First issue date and final maturity date</HelperText>
          <HelperText>- Outstanding amount as of reporting date</HelperText>
          <HelperText>
            - Interest rate and accrued but unpaid interest as of reporting date
          </HelperText>
          <HelperText>
            - Guarantees and assets granted as security in favour of Nordic
            Trustee
          </HelperText>
          <br />
          <HelperText>
            *Note that Nordic Trustee may charge an administrative fee for
            preparing and issuing an audit confirmation, depending on type and
            number of instruments, information requested and level of
            complexity.
          </HelperText>
        </Box>
        <SubmitButton text="Send Request" loading={sendingRequest} />
      </Box>
    </ValidatorForm>
  );
};
