import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
// TODO: replace the buttons with the Button and set props accordingly
import { Card, ContainedButton, EditButton } from '@nordictrustee/nt-ui-library';
import moment from 'moment';
import { useReporting } from '../ReportingContext';
import { ReportingEventDTO } from '../types';
import { EventOfDefault } from './EventOfDefault';
import { InformationCovenants } from './InformationCovenants';
import { ReportingRemark } from './ReportingRemark';
import { ReportingSubmitForm } from './ReportingSubmitForm';
import { ReportingUploads } from './ReportingUploads';
import { useReportingDetails } from './useReportingDetails';

export const ReportingDetails: FC<{ reportingEvent: ReportingEventDTO }> = ({ reportingEvent }) => {
  const { loanID } = useReporting();
  const [isReportingSubmitFormOpen, setIsReportingSubmitFormOpen] =
    useState(false);
  const {
    isLoadingReportingDetails,
    hasSubmittedReport,
    reportingDetails,
    fetchReportingDetails,
  } = useReportingDetails(loanID, reportingEvent.id);

  const startReportingButton = useMemo(() => {
    return hasSubmittedReport ?
      <EditButton onClick={() => { setIsReportingSubmitFormOpen(true) }} /> :
      <ContainedButton onClick={() => { setIsReportingSubmitFormOpen(true) }}>Start Reporting</ContainedButton>
  }, [hasSubmittedReport]);

  const reportingTitle = `${moment(reportingEvent.deadlineDate).format('DD')} ${moment(reportingEvent.deadlineDate).format('MMMM')} ${moment(reportingEvent.deadlineDate).format('YYYY')} - ${reportingEvent.name}`;

  const handleCloseSubmitForm = useCallback(
    async (updatedReportID?: number) => {
      if (updatedReportID != null) {
        await fetchReportingDetails();
      }
      setIsReportingSubmitFormOpen(false);
    },
    [fetchReportingDetails]
  );

  const isLoading = reportingDetails === undefined || isLoadingReportingDetails;

  return (
    <>
      <Card title={reportingTitle} customAction={startReportingButton} data-cy="reporting-details-container" withoutPadding>
        <Divider />
        <Stack spacing={2} mb={2}>
          <InformationCovenants informationCovenants={reportingDetails?.informationCovenants} isLoading={isLoading} />
          <div>
            <Box my={2} mx={2}>
              <Typography variant="h4">Reporting</Typography>
            </Box>
            <ReportingUploads fileUploads={reportingDetails?.complianceDocuments || []} isLoading={isLoading} />
            <Grid container spacing={2} mx={0} mt={4}>
              <Grid item xs="auto">
                <EventOfDefault hasConfirmedNoEventOfDefault={reportingDetails?.hasConfirmedNoEventOfDefault || false} isLoading={isLoading} />
              </Grid>
              <Grid item xs>
                <ReportingRemark comment={reportingDetails?.comment || ''} isLoading={isLoading} />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Card>
      {isReportingSubmitFormOpen &&
        <ReportingSubmitForm
          handleClose={handleCloseSubmitForm}
          covenantScheduleID={reportingEvent.id}
          reportingName={reportingEvent.name}
          reportingDate={reportingEvent.deadlineDate}
          referenceDate={reportingEvent.referenceDate}
          reportingDetails={reportingDetails!}
          isEditMode={hasSubmittedReport}
        />
      }
    </>
  );
};
