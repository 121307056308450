import { FC, useCallback, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { Input, Table } from '@nordictrustee/nt-ui-library';
import { useToast } from 'modules/Global';
import { FileIcon } from '../../FileIcon';
import { centerColumnText } from '../../tableStyles';
import { FileInfo } from './types';

export const FileUploads: FC<{
  files: FileInfo[];
  onChange: (FileInfo: FileInfo[]) => void;
  isEditMode: boolean;
  isSwedishTrustee: boolean;
}> = ({ files, onChange, isEditMode, isSwedishTrustee }) => {
  const { showWarning, showError } = useToast();
  const onFileUpdate = useCallback(
    (item: FileInfo, checked: boolean) => {
      const update = files.map((file) => {
        if (file.fileName === item.fileName) {
          return {
            ...file,
            publish: checked,
            fileName: file.file?.name || file.fileName,
          };
        }
        return file;
      });
      onChange(update);
    },
    [files, onChange],
  );

  const onFileDelete = useCallback(
    (fileName: string) => {
      onChange(files.filter((f) => f.fileName !== fileName));
    },
    [files, onChange],
  );

  const isEditingForExistingDisabled = useCallback(
    (fileID?: number) => {
      return !isEditMode && fileID != null && fileID > 0;
    },
    [isEditMode],
  );

  const columns: Table.Column<FileInfo>[] = useMemo(
    () => [
      {
        field: 'isRequestPublishToStamdata',
        title: (
          <Table.ColumnWithTooltip title="This box shall be ticked if you want Nordic Trustee to publish the document on Stamdata.com. Please note that a compliance certificate shall normally not be made publicly available. ">
            Request publish to Stamdata
            <InfoIcon />
          </Table.ColumnWithTooltip>
        ),
        width: '25%',
        render: (rowData) => (
          <Table.ColumnWithTooltip
            title={
              isEditingForExistingDisabled(rowData.id)
                ? 'Cannot do changes to existing file before a report has been submitted'
                : ''
            }
          >
            <Input.Checkbox
              label=""
              name="isPublishToStamdata"
              onChange={(checked) => onFileUpdate(rowData, checked)}
              checked={rowData.publish}
              disabled={isEditingForExistingDisabled(rowData.id)}
            />
          </Table.ColumnWithTooltip>
        ),
        ...centerColumnText,
        hidden: isSwedishTrustee,
      },
      {
        field: 'fileName',
        title: 'File name',
        width: isSwedishTrustee ? '100%' : '70%',
        render: ({ fileName }) => {
          return (
            <Table.ColumnWithTooltip title={fileName}>
              <FileIcon filename={fileName} /> {fileName}
            </Table.ColumnWithTooltip>
          );
        },
      },
    ],
    [isEditingForExistingDisabled, isSwedishTrustee, onFileUpdate],
  );

  const totalFileSize = useMemo(() => {
    let totalBytes = 0;
    files.forEach((file) => {
      totalBytes += file.file?.size | 0;
    });
    const totalFileSize = totalBytes / 1024 / 1024;
    return totalFileSize;
  }, [files]);
  const acceptedFiles = {...Input.FileUpload.defaultMimeTypes};
  delete acceptedFiles['text/plain'];
  return (
    <Input.FileUpload
      files={files.map((f) => ({
        filename: f.file?.name || f.fileName,
        file: f.file,
      }))}
      accept={acceptedFiles}
      maxSizeBytes={500 * 1024 * 1024}
      onDrop={(acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
          fileRejections.forEach(({ errors, file }) => {
            if (errors.map(({ code }) => code).includes('duplicate-file'))
              showWarning(`File "${file.name}" is already in the list`);
            else {
              showError(`File "${file.name}" is not accepted`);
            }
          });
        }
        onChange([
          ...files,
          ...acceptedFiles.map((file: File) => {
            return {
              fileName: file.name,
              file,
              publish: false,
            };
          }),
        ]);
      }}
    >
      <Typography variant="body2" color="textSecondary">
        Please upload documentation which shall be delivered according to Bond
        Terms and are not available from public sources.
      </Typography>
      <Table.Root
        columns={columns as any}
        data={files.map((file, id) => ({
          id,
          ...file,
          fileName: file.file?.name || file.fileName,
          publish: file.publish,
        }))}
        options={{
          paging: false,
          search: false,
          toolbar: false,
          maxBodyHeight: '70vh',
          actionsColumnIndex: -1,
          searchFieldAlignment: 'left',
          pageSizeOptions: [10, 25, 50, 100],
          rowStyle: { padding: '0 1rem' },
        }}
        actions={[
          (_rowData: FileInfo) => ({
            icon: () => <DeleteIcon />,
            onClick: (_, rowData) => {
              if (Array.isArray(rowData)) return;
              onFileDelete(rowData.fileName);
            },
            disabled: isEditingForExistingDisabled(_rowData.id),
            tooltip: isEditingForExistingDisabled(_rowData.id)
              ? 'Cannot delete existing file before a report has been submitted'
              : 'Delete',
          }),
        ]}
      />
      <Typography
        variant="body2"
        color="textSecondary"
      >{`Total File Size: ${totalFileSize.toFixed(2)} MB`}</Typography>
    </Input.FileUpload>
  );
};
