import { Box, Grid } from '@mui/material';
import { DocumentDTO } from 'api/api.types';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';
import { DocumentCard } from './DocumentCard';
import { DocumentUploadButton } from './DocumentUpload';

export type OpenUploadFormTypes = 'reportingDocuments' | 'otherDocuments';

interface ComponentProps {
  documents: DocumentDTO[];
  issuerID?: number;
  loanID?: number;
  isin: string;
  openUploadForm?: OpenUploadFormTypes;
  covenantReportingID?: string;
  reportingDate?: string;
  onUploadSuccess?: () => void;
  loading: boolean;
}

const sortByDateDescending = (a: DocumentDTO, b: DocumentDTO) => {
  if (a.date === undefined) { return -1; }
  if (b.date === undefined) { return 1; }
  if (a.date < b.date) { return 1; }
  if (a.date > b.date) { return -1; }
  return 0;
};

const sortByNameAscending = (a: DocumentDTO, b: DocumentDTO) => (a.name || '').localeCompare((b.name || ''));

export const DocumentsListDetails = ({
  issuerID, loanID, openUploadForm, covenantReportingID, isin, reportingDate, documents, onUploadSuccess, loading,
}: ComponentProps) => {
  const loanDocuments = documents.filter((document) => document.type === HubDocumentTypeUniverseEnum.Loan).sort(sortByNameAscending);
  const communicationFromAgentDocuments = (
    documents.filter((document) => document.type === HubDocumentTypeUniverseEnum.Market).sort(sortByDateDescending));
  const reportingDocuments = (
    documents.filter((document) => document.type === HubDocumentTypeUniverseEnum.Reporting).sort(sortByDateDescending));
  const internalDocuments = (
    documents.filter((document) => document.type === HubDocumentTypeUniverseEnum.Internal).sort(sortByDateDescending));

  return (

    <div>
      <Grid container spacing={2}>
        {loanDocuments && loanDocuments.length > 0 && (
          <Grid item xs={12}>
            <DocumentCard
              documents={loanDocuments}
              cardTitle="Loan Documents"
              showUploadedBy={false}
              showReferenceDate={false}
              showUploadedDate={false}
              showReviewed={false}
              showIdentifier={loanID === undefined}
              loading={loading}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DocumentCard
            documents={reportingDocuments}
            cardTitle="Issuer Reporting"
            action={loanID && issuerID && (
              <Box
                displayPrint="none"
                data-id="Issuer Reporting"
              >
                <DocumentUploadButton
                  documentType={HubDocumentTypeUniverseEnum.Reporting}
                  onUploadSuccess={onUploadSuccess}
                  issuerID={issuerID}
                  loanID={loanID}
                  defaultOpen={openUploadForm === 'reportingDocuments'}
                  covenantReportingID={covenantReportingID}
                  isin={isin}
                  title="Upload a new document"
                  reportingDate={reportingDate}
                />
              </Box>
            )}
            showUploadedBy
            showReferenceDate
            showUploadedDate
            showReviewed
            showIdentifier={loanID === undefined}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <DocumentCard
            documents={communicationFromAgentDocuments}
            cardTitle="Information Distributed to Bondholders"
            action={loanID && issuerID && (
              <Box
                displayPrint="none"
                data-id="Information Distributed to Bondholders"
              >
                <DocumentUploadButton
                  documentType={HubDocumentTypeUniverseEnum.Market}
                  onUploadSuccess={onUploadSuccess}
                  issuerID={issuerID}
                  loanID={loanID}
                  isin={isin}
                  title="Request Publication to Stamdata"
                />
              </Box>
            )}
            additionalInfo="Documents made available to the public by Nordic Trustee via www.stamdata.com"
            showUploadedBy={false}
            showReferenceDate={false}
            showUploadedDate
            showReviewed={false}
            showIdentifier={loanID === undefined}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <DocumentCard
            documents={internalDocuments}
            cardTitle="Internal Documents"
            action={loanID && issuerID && (
              <Box displayPrint="none" data-id="Internal Documents">
                <DocumentUploadButton
                  documentType={HubDocumentTypeUniverseEnum.Internal}
                  onUploadSuccess={onUploadSuccess}
                  issuerID={issuerID}
                  loanID={loanID}
                  defaultOpen={openUploadForm === 'otherDocuments'}
                  isin={isin}
                  title="Upload a new document"
                />
              </Box>
            )}
            showUploadedBy
            showReferenceDate={false}
            showUploadedDate
            showReviewed={false}
            showIdentifier={loanID === undefined}
            loading={loading}
          />
        </Grid>
      </Grid>
    </div>
  );
};

