import {
  Chart,
  ArcElement,
  BarElement,
  CategoryScale,
  DoughnutController,
  Legend,
  LineElement,
  LinearScale,
  TimeScale,
  Tooltip,
  PointElement,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

export const initChartjs = () => {
  Chart.register(
    ArcElement,
    BarElement,
    CategoryScale,
    DoughnutController,
    Legend,
    LineElement,
    LinearScale,
    TimeScale,
    Tooltip,
    PointElement,
    Filler,
  );
};
