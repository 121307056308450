import Typography from '@mui/material/Typography';
import { HubDocumentTypeUniverseEnum } from 'modules/Global/enums';

interface ComponentProps {
  documentType: HubDocumentTypeUniverseEnum;
}

export const DocumentType = ({
  documentType,
}: ComponentProps) => {
  let type;
  let info = '';
  switch (documentType) {
    case HubDocumentTypeUniverseEnum.Market:
      type = 'Information Distributed to Bondholders';
      info = ' (after being reviewed by NT loan operations)';
      break;
    case HubDocumentTypeUniverseEnum.Internal:
      type = 'Internal Documents';
      break;
    case HubDocumentTypeUniverseEnum.Reporting:
      type = 'Issuer Reporting';
      break;
    case HubDocumentTypeUniverseEnum.Loan:
      type = 'Loan Documents';
      break;
    default:
      throw new Error('Invalid document type');
  }

  return (
    <Typography variant="body1" component="span">
      {`The files will be uploaded to the "${type}" folder${info}.`}
    </Typography>
  );
};

