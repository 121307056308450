import React from 'react';
import { Label } from './Label';
import classes from './Legend.module.css';

type LabelsProps = {
  remaining: number;
  color1: string;
  color2: string;
  color3: string;
  currentLabel?: string;
  currentValue?: string | number;
  secondLabel?: string;
  secondValue?: string | number;
  description?: string;
};

export const Legend: React.FC<LabelsProps> = ({
  remaining,
  color1,
  color2,
  color3,
  currentLabel,
  currentValue,
  secondLabel,
  secondValue,
}) => {
  return !currentLabel ? null : (
    <div className={classes.labels}>
      <Label
        color={remaining === 0 ? color1 : color2}
        label={currentLabel}
        value={currentValue || ''}
      />
      {secondLabel ? (
        <Label color={color3} label={secondLabel} value={secondValue || ''} />
      ) : null}
    </div>
  );
};
