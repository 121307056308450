import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { MaturityProfileDTO } from 'api/api.types';
import {
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
  TooltipItem,
} from 'chart.js';
import { CurrencyCodeUniverseEnum } from 'modules/Global/enums';
import { getChartColor } from 'modules/Global/theme';
import { formatMoney, humanizeMoney } from 'utils/money';

interface MaturityProfileChartProps{
  maturityProfile: MaturityProfileDTO | undefined;
}

const getOptions = (currency: CurrencyCodeUniverseEnum | undefined): ChartOptions<'bar'> => ({
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      ticks: {
        callback: (tickValue: number | string, _index: number, _ticks: any[]) => {
          return humanizeMoney(currency || '', +tickValue);
        },
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItem: TooltipItem<keyof ChartTypeRegistry>) {
          const label: string = tooltipItem.label || 'N/A';
          const val: number = tooltipItem.raw as number;
          return `${label}: ${formatMoney(val || 0, currency?.toString())}`;
        },
      },
    },
  },
});

const getChartData = (maturityProfile: MaturityProfileDTO | undefined): ChartData<'bar'> => {
  if (!maturityProfile) {
    return { datasets:[]};
  }

  const keys = Object.keys(maturityProfile.maturityProfile);

  if (!keys || keys.length === 0) {
    return {datasets:[]};
  }

  const capitalizeWords = (word: string): string => word.replace(/\w\S*/g, (txt) => {
    const firstChar = txt.charAt(0).toUpperCase();
    const restOfWord = txt.substr(1).toLowerCase();
    return (firstChar + restOfWord);
  });

  const datasets = keys.map((key, i) => (
      {
        label: capitalizeWords(key),
        backgroundColor: getChartColor(i),
        borderWidth: 0,
        data: maturityProfile.maturityProfile[key].map((m) => m.amount || 0),
      }
  ));

  const labels = maturityProfile.maturityProfile[keys[0]].map((m) => m.year ?? 0);

  return {
    labels,
    datasets,
  };
};

export const MaturityProfileChart = ({ maturityProfile }: MaturityProfileChartProps) => {
  const chartData = getChartData(maturityProfile);
  const options = getOptions(maturityProfile?.currency);
  return (
      <Box style={{height: '237px'}} mt={1}>
        <Bar data={chartData} options={options} />
      </Box>
  );
};
