import { FC } from 'react';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';
import { DateBlock } from 'components/DateBlock';
import { daysDiffText } from 'utils/localization/localization';
import { ReportingEventDTO } from '../types';
import { isEventUpcoming } from './isEventUpcoming';
import { StatusChip } from './StatusChip';
import { useReportingSelect } from './useReportingSelect';
import classes from './ReportingList.module.css';

type ReportingListProps = {
  loading: boolean;
  reportingEvents?: ReportingEventDTO[];
  selectEvent: (reporting: ReportingEventDTO) => void;
};

export const ReportingList: FC<ReportingListProps> = ({
  loading,
  reportingEvents,
  selectEvent,
}) => {
  const { selectedReportingId } = useReportingSelect({
    reportingEvents
  });
  return (
    <List className={classes.list}>
      {!loading && !reportingEvents?.length && (
        <ListItemText
          primary={
            <Box>
              <Typography>No reporting events found.</Typography>
            </Box>
          }
        />
      )}
      {reportingEvents &&
        reportingEvents.map((reporting: ReportingEventDTO) => {
          const selected = reporting.id === selectedReportingId;
          return (
            <ListItemButton
              key={reporting.id}
              divider
              onClick={() => selectEvent(reporting)}
              selected={selected}
              autoFocus={selected}
            >
              <DateBlock date={reporting.deadlineDate!} />
              <ListItemText
                primary={
                  <Box display="flex">
                    <Typography variant="h4">{reporting.name}</Typography>
                  </Box>
                }
                secondary={
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.secondaryText}
                  >
                    <Box display="flex" alignItems="start" component="span">
                      {isEventUpcoming(reporting) ? (
                        <>
                        <LabelImportantIcon
                          className={classes.upcoming}
                        />
                          {`${daysDiffText(moment(reporting.deadlineDate))} - `}
                        </>
                      ) : (
                        ''
                      )}{' '}
                      {`Reporting deadline for reference date ${moment(reporting.referenceDate).format('L')}`}
                    </Box>
                  </Typography>
                }
              />
              <StatusChip {...reporting} />
            </ListItemButton>
          );
        })}
    </List>
  );
};
