import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
  TooltipItem,
} from 'chart.js';
import theme from 'modules/Global/theme';
import { humanizeMoney } from 'utils/money';
import { ChartText } from './ChartText';
import { Legend } from './Legend';
import { ChartSize } from './types';
import classes from './InfoDoughnut.module.css';

interface ComponentProps {
  currentValue: number;
  currentValueLabel?: string;
  inProgressText?: string;
  secondaryLabel?: string;
  maximumValue: number;
  completeText: string;
  thickness?: number;
  size?: ChartSize;
  currency: string;
}

const defaultThickness = 75;
type DoughnutChartData = ChartData<'doughnut'>;

export const InfoDoughnut: React.FC<ComponentProps> = ({
  currentValue,
  maximumValue,
  inProgressText,
  currentValueLabel,
  secondaryLabel,
  completeText,
  thickness,
  size,
  currency,
}) => {
  const remainingAmount = maximumValue > 0 ? maximumValue - currentValue : 0;
  const remainingText = humanizeMoney(currency, remainingAmount, true);
  const currentValueText = humanizeMoney(currency, currentValue, true);


  const color1 = theme.palette.success.main;
  const color2 = theme.palette.primary.main;
  // @ts-ignore TODO: redefine palette
  const color3 = theme.palette.primary.extraLight;
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: `${thickness || defaultThickness}%`,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(tooltipItem: TooltipItem<keyof ChartTypeRegistry>) {
            if (data.datasets && data.datasets[0] && data.datasets[0].data) {
              const label: number = data.datasets[0].data[
                tooltipItem.dataIndex || 0
              ] as number;
              return humanizeMoney(currency, label);
            }
            return '';
          },
        },
      },
    },
  };

  const data: DoughnutChartData = {
    datasets: [
      {
        data: [currentValue, remainingAmount >= 0 ? remainingAmount : 0],
        backgroundColor: [
          remainingAmount > 0
            ? color2
            : color1,
          color3,
        ],
        borderWidth: 0,
      },
    ],
  };

  const chartContainerClass =
    size === ChartSize.Sm
      ? classes.chartContainerSmall
      : classes.chartContainer;

  return (
    <div className={classes.root}>
      <div className={chartContainerClass}>
        <Doughnut options={options} data={data} />
        <div data-cy="chart-legends" />

        <ChartText
          valueText={
            remainingAmount <= 0 ? completeText || remainingText : remainingText
          }
          size={size}
          inProgress={
            remainingAmount > 0 || (remainingAmount <= 0 && !completeText)
          }
          inProgressText={inProgressText}
        />
      </div>
      <Legend
        color1={color1}
        color2={color2}
        color3={color3}
        currentLabel={currentValueLabel}
        currentValue={currentValueText}
        secondLabel={secondaryLabel}
        secondValue={remainingText}
        remaining={remainingAmount}
      />
    </div>
  );
};
