import { useEffect } from 'react';
import moment from 'moment/moment';
import { useReporting } from '../ReportingContext';
import { ReportingEventDTO } from '../types';

interface Options {
  reportingEvents?: ReportingEventDTO[];
}

export const useReportingSelect = ({
  reportingEvents,
}: Options) => {
  const { reportId, selectEvent } = useReporting();

  useEffect(() => {
    if (!reportingEvents) return;
    if (reportId) {
      const reportingWithinFilter = reportingEvents.find(
        ({ id }) => id === +reportId,
      );
      if (reportingWithinFilter) {
        selectEvent(reportingWithinFilter);
      }
    } else {
      // TODO replace when statuses are added
      const defaultSelection =
        reportingEvents.find((r) => moment(r.deadlineDate) >= moment()) ||
        reportingEvents[0];
      selectEvent(defaultSelection, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only if reportId and reportings change
  }, [reportId, reportingEvents]);

  return { selectedReportingId: reportId ? +reportId : undefined };
};
