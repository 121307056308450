import React from 'react';
import {Typography } from '@mui/material';
import { ChartSize } from './types';
import classes from './ChartText.module.css';

interface CompleteTextProps {
  valueText: string | number;
  inProgressText?: string;
  inProgress: boolean;
  size?: ChartSize;
}

export const ChartText = ({
  valueText,
  inProgressText,
  size,
  inProgress,
}: CompleteTextProps) => {
  const textSizeClass = size !== ChartSize.Sm ? classes.bigText : '';
  const InnerText = inProgress ? (
    <>
      <Typography
        align="center"
        color="primary"
        className={`${textSizeClass} ${classes.wrapText}`}   
      >
        {valueText}
      </Typography>
      <Typography align="center" variant="body1" color="primary">
        {inProgressText}
      </Typography>
    </>
  ) : (
    <Typography
      align="center"
      className={`${textSizeClass} ${classes.completeText}`}
      color="primary"
    >
      {valueText}
    </Typography>
  );
  return <div className={classes.chartText}>{InnerText}</div>;
};
