import { useEffect, useState } from 'react';
import { getReportings } from './ReportingApi';
import { useReporting } from './ReportingContext';
import { ReportingEventDTO } from './types';

export const useReportingItems = () => {
  const {loanID, from, to} = useReporting();
  const [loading, setLoading] = useState(false);
  const [reportings, setReportings] = useState<ReportingEventDTO[]|undefined>();

  useEffect(() => {
    if(!from || !to) return;
    let cancel = false;
    (async () => {
      setLoading(true);
      const reportingDTOs = await getReportings(+loanID, from, to);
      if (cancel) return;

      setReportings(reportingDTOs);
      setLoading(false);
    })();
    return () => {
      cancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only if reportId changes, from and to changes
  }, [from, to]);

  return { loading, reportingEvents: reportings };
};